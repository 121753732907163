
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatBadge1(badge, badgeIndex) {
                    return _createElement('div', {
                        'className': 'badge cm_badge cm_badge__' + this.toKebabCase(badge),
                        'key': badge
                    }, '\n        ', badge, '\n      ');
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI3(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('div', { 'className': 'cm_product-item grid-item__link' }, _createElement('a', {
                    'href': this.url,
                    'className': 'cm_product-item__image-container'
                }, _createElement('img', {
                    'className': 'cm_product-item__image' + (this.image2 ? ' cm_product-item__image_primary' : ''),
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'cm_product-item__image cm_product-item__image_secondary',
                    'src': this.resizeImage(this.image2),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '355'
                }) : null, this._badges ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_badge-container cm_badge-right',
                        'key': '585'
                    },
                    _map(this._badges, repeatBadge1.bind(this))
                ]) : null), _createElement('div', { 'className': 'cm_product-item__details' }, _createElement('a', mergeProps({
                    'href': this.vendor_url,
                    'className': 'cm_product-item__vendor'
                }, { dangerouslySetInnerHTML: { __html: this.vendor } })), _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'cm_product-item__title'
                }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'cm_product-item__sku-container' }, this.sku ? _createElement('div', {
                    'className': 'cm_product-item__sku',
                    'key': '1161'
                }, _createElement('span', { 'className': 'sku-title' }, 'SKU: '), _createElement('span', mergeProps({ 'className': 'sku-value' }, { dangerouslySetInnerHTML: { __html: this.sku } }))) : null), _createElement('div', { 'className': 'cm_product-item__price-container' }, this.on_sale ? _createElement('div', {
                    'className': 'cm_product-item__price cm_product-item__price_compare',
                    'key': '1431'
                }, '\n        ', this.formatPrice(this.compare_at_price), '\n      ') : null, _createElement('div', { 'className': 'cm_product-item__price' }, this.price_varies ? _createElement('span', { 'key': '1634' }, 'From: ') : null, '\n        ', this.formatPrice(this.price), '\n      '), this.set_qty && !this.price_varies ? _createElement('div', {
                    'className': 'cm_product-item__price cm_product-item__price_set',
                    'key': '1738'
                }, '\n        Set of ', this.set_qty, ': ', this.formatPrice(this.price * this.set_qty), '\n      ') : null)), _createElement('div', { 'className': 'cm_product-item__buttons' }, _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'cm_product-item__buttons-form',
                    'id': this.id,
                    'encType': 'multipart/form-data'
                }, this.variant_ids?.length > 1 ? [_createElement('a', {
                        'className': 'cm_button cm_button__secondary',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '21371'
                    }, '\n          ', this.out_of_stock ? 'Sold out' : 'Select options', '\n        ')] : null, this.variant_ids && this.variant_ids.length === 1 || !this.variant_ids ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids ?? this.id,
                        'key': '24001'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': this.set_qty || 1,
                        'key': '24003'
                    }),
                    _createElement('button', {
                        'className': 'cm_button cm_button__primary',
                        'type': 'submit',
                        'data-form-id': this.id,
                        'disabled': this.out_of_stock,
                        'key': '24005'
                    }, this.out_of_stock ? ['Sold out'] : null, !this.out_of_stock ? [
                        '\n            Add to cart',
                        this.set_qty ? ` Set of ${ this.set_qty }` : '',
                        '\n          '
                    ] : null)
                ] : null)), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '30720'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI3.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '3471'
                }) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []